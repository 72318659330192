<template>
  <v-container>
    <v-row>
      <v-col>
        <h3 class="text-center">{{ pageTitle }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" align="center">
        <p class="font-weight-bold">Imported</p>
        <p>(Import {{ importedDataCaption }})</p>
        <v-btn :disabled="true">
          DONE
        </v-btn>
      </v-col>
      <v-col cols="4" align="center">
        <p class="font-weight-bold">Mapping</p>
        <p>(Match imported {{ importedDataCaption }})</p>
        <v-btn :disabled="true">
          DONE
        </v-btn>
      </v-col>
      <v-col cols="4" align="center">
        <p class="font-weight-bold">Completed(Process)</p>
        <p>(Mark matched {{ importedDataCaption }} paid?)</p>
        <v-btn
          :disabled="isUpdated"
          depressed
          color="primary"
          @click="updateTransactions"
        >
          {{ isUpdated ? 'Done' : 'Proceed' }}
        </v-btn>
      </v-col>
    </v-row>
    <p></p>
    <hr>
    <p></p>
    <h3 class="text-center">MATCHED {{ importedDataCaption.toUpperCase() }}</h3>
    <template>
      <v-data-table
        :headers="transactionDetails.matchedData.headers"
        :items="transactionDetails.matchedData.data"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-1"
      >
        <template slot="body.append">
          <tr class="pink--text">
              <td colspan="3">
                Total for matched {{ importedDataCaption }}:
                ${{amountFormat(transactionDetails.matchedData.totalAmount) }}
              </td>
          </tr>
      </template>
      </v-data-table>
    </template>
    <p></p>
    <hr>
    <p></p>
    <h3 class="text-center">UNMATCHED {{ importedDataCaption.toUpperCase() }}</h3>
    <template>
      <v-data-table
        :headers="transactionDetails.unmatchedData.headers"
        :items="transactionDetails.unmatchedData.data"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-1"
      >
        <template slot="body.append">
          <tr class="pink--text">
              <td colspan="3">
                Total for unmatched {{ importedDataCaption }}:
                ${{ amountFormat(transactionDetails.unmatchedData.totalAmount) }}
              </td>
          </tr>
        </template>
      </v-data-table>
    </template>
    <v-overlay :value="overlay">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="value"
        color="primary"
      >
        {{ value }}
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import {
  amountFormat,
  amountToNumber,
} from '../../../../util/shared/vue-global';

const initdata = () => ({
  overlay: false,
  value: 0,
  isUpdated: false,
});

export default {
  name: 'ImportFileProcessedDetails',
  data: () => initdata(),
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    importedDataCaption: {
      type: String,
      default: '',
    },
    gqlfields: {
      type: Object,
      default: () => {},
    },
    dataMapper: {
      type: Function,
      default: () => {},
    },
    loadImportedData: {
      type: Function,
      default: () => {},
    },
    dataHeaders: {
      type: Function,
      default: () => {},
    },
    upsertDataMapper: {
      type: Function,
      default: () => {},
    },
    upsertImportedData: {
      type: Function,
      default: () => {},
    },
    markPaidInGroup: {
      type: Boolean,
      default: () => false,
    },
    getTransactions: {
      type: Function,
      default: () => {},
    },
    upsertGroupedData: {
      type: Function,
      default: () => {},
    },
  },
  created() {
  },
  mounted() {
    this.loadImportedData({
      gqlfields: this.gqlfields,
      postProccess: ({ transData }) => {
        const processedFileData = {
          matchedData: {
            headers: this.dataHeaders().matched,
            data: [],
            totalAmount: 0,
          },
          unmatchedData: {
            headers: this.dataHeaders().unmatched,
            data: [],
            totalAmount: 0,
          },
        };
        if (Array.isArray(transData) && transData.length > 0) {
          for (let i = 0; i < transData.length; i += 1) {
            const trans = transData[i];
            if (trans.transactionDetails !== null) {
              processedFileData.matchedData.data.push(this.dataMapper(trans, true));
              processedFileData.matchedData.totalAmount += amountToNumber(trans.amount);
            } else {
              processedFileData.unmatchedData.data.push(this.dataMapper(trans));
              processedFileData.unmatchedData.totalAmount += amountToNumber(trans.amount);
            }
          }
        }
        return processedFileData;
      },
    });
  },
  computed: {
    ...mapState({
      transactionDetails: (state) => state.Bulk.processedFileData,
    }),
  },
  methods: {
    ...mapMutations([
      'setPrintScreen',
    ]),
    amountFormat,
    ...mapActions(['flashInfo']),
    async updateMatchedTransactionStatus() {
      if (window.confirm('Are you sure want to update all matched records?')) {
        const matchedData = ((this.transactionDetails || {}).matchedData || {}).data || [];
        this.overlay = true;
        for (let i = 0; i < matchedData.length; i += 1) {
          const item = await this.upsertDataMapper({ ...matchedData[i] });
          await this.upsertImportedData(item);
          this.value = parseInt(((100 * (i + 1)) / matchedData.length), 10);
        }
        this.overlay = false;
        this.isUpdated = true;
        this.flashInfo(`Successfully ${matchedData.length} records have updated.`);
        this.$nextTick(() => {
          window.print();
        });
      }
    },
    async updateTransactions() {
      if (this.markPaidInGroup) {
        if (window.confirm('Are you sure want to update all matched records?')) {
          const matchedData = ((this.transactionDetails || {}).matchedData || {}).data || [];
          this.overlay = true;
          const fetchedGroupTransactions = await this.getTransactions(matchedData);
          let index = 0;
          const groupedTransLength = Object.keys(fetchedGroupTransactions).length;
          for (const [key, value] of Object.entries(fetchedGroupTransactions)) {
            await this.upsertGroupedData(key, value);
            this.value = parseInt(((100 * (index + 1)) / groupedTransLength), 10);
            index += 1;
          }
          this.overlay = false;
          this.isUpdated = true;
          this.flashInfo(`Successfully ${matchedData.length} records have updated.`);
          this.setPrintScreen(true);
          this.$nextTick(() => {
            window.print();
            this.$nextTick(() => {
              this.setPrintScreen(false);
            });
          });
        }
      } else {
        this.updateMatchedTransactionStatus();
      }
    },
  },
};
</script>
