<template>
  <div>
    <ImportFileProcessedDetails
      pageTitle="Paid Warrants Processed Details"
      importedDataCaption="warrants"
      :gqlfields="gqlfields"
      :loadImportedData="loadImportedPaidWarrants"
      :dataHeaders="dataHeaders"
      :dataMapper="dataMapper"
      :upsertDataMapper="upsertDataMapper"
      :upsertImportedData="upsrtTransaction"
      :markPaidInGroup='true'
      :getTransactions="fetchTransData"
      :upsertGroupedData="upsertWarTrans"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ImportFileProcessedDetails from '@/components/common/base/report/ImportFileProcessedDetails.vue';
import { amountFormat } from '../../util/shared/vue-global';
import { createWarTransactionGroup, upsertTransactionItem } from '../War/Transaction/warTransaction.util';

const initdata = () => ({
  profileType: '',
  gqlfields: {
    amount: '',
    warrant: '',
    date: '',
    transactionDetails: {
      _id: '',
      fiscalYear: '',
      registerDate: '',
      warrant: '',
    },
  },
  baseCrudKey: 'warTransaction',
});

const dataHeaders = () => ({
  matched: [
    { text: 'FY', value: 'fiscalYear' },
    { text: 'Registration Date', value: 'registerDate' },
    { text: 'Warrant', value: 'warrant' },
    { text: 'Amount', value: 'amount' },
    { text: 'Paid Date', value: 'date' },
  ],
  unmatched: [
    { text: 'Date', value: 'date' },
    { text: 'Warrant/Check Number', value: 'warrant' },
    { text: 'Amount', value: 'amount' },
  ],
});

const dataMapper = (transaction, isMatchedData) => {
  let mappedData;
  if (isMatchedData) {
    mappedData = {
      amount: amountFormat(transaction.amount),
      date: transaction.date,
      transactionId: transaction.transactionDetails._id,
      warrant: transaction.transactionDetails.warrant,
      registerDate: transaction.transactionDetails.registerDate,
      fiscalYear: transaction.transactionDetails.fiscalYear,
    };
  } else {
    mappedData = {
      warrant: transaction.warrant,
      amount: amountFormat(transaction.amount),
      date: transaction.date,
    };
  }
  return mappedData;
};

export default {
  name: 'PaidWarrantsProcessedDetails',
  data: () => initdata(),
  components: {
    ImportFileProcessedDetails,
  },
  mounted() {},
  props: {
    program: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('Bulk', [
      'loadImportedPaidWarrants',
    ]),
    ...mapActions('war/transaction', [
      'upsrtTransaction',
      'loadTransactionList',
      'loadLastPaymentNo',
    ]),
    dataMapper,
    dataHeaders,
    async getPaymentNumberByTransId(transactionId) {
      const transaction = (await this.loadTransactionList(
        { criteria: { _id: transactionId }, programKey: this.program },
      ))[0] || {};
      const { fiscalYear } = transaction;
      const fund = (((transaction.details || [])[0] || {}).fund || {})._id || '';
      let paymentNumber = 0;
      if (fund && fiscalYear) {
        paymentNumber = (((await this.loadLastPaymentNo({
          fund, fiscalYear, baseCrudKey: this.program,
        })).warStatsMaxPayment || {}).max || 0) + 1;
      }
      return paymentNumber;
    },
    async upsertDataMapper(item) {
      const payment = await this.getPaymentNumberByTransId(item.transactionId);
      const buildedItem = {
        item: {
          _id: item.transactionId,
          status: 'PAID',
          paidCancelledDate: item.date,
          payment,
        },
      };
      return buildedItem;
    },
    async getWarTransactions(trans = []) {
      const transactionList = [];
      for (let i = 0; i < (trans || []).length; i += 1) {
        const transResponse = (await this.loadTransactionList(
          { criteria: { _id: trans[i].transactionId }, programKey: this.program },
        ))[0] || {};
        transResponse.paidCancelledDate = trans[i].date;
        transactionList.push(transResponse);
      }
      return transactionList;
    },
    async fetchTransData(trans = []) {
      const warTrans = await this.getWarTransactions(trans);
      const groupedTrans = createWarTransactionGroup(warTrans);
      return groupedTrans;
    },
    async upsertWarTrans(key, value) {
      const keyObj = JSON.parse(key);
      const { fiscalYear, fundId } = keyObj;
      let paymentNumber = 0;
      if (fundId && fiscalYear) {
        paymentNumber = (((await this.loadLastPaymentNo({
          fund: fundId,
          fiscalYear,
          baseCrudKey: this.program,
        }))
          .warStatsMaxPayment || {}).max || 0) + 1;
      }
      for (let i = 0; i < (value || []).length; i += 1) {
        const updateItem = JSON.parse(JSON.stringify(value[i]));
        updateItem.status = 'PAID';
        updateItem.payment = paymentNumber;
        const cleanedTransItem = await upsertTransactionItem(updateItem);
        await this.upsrtTransaction({ item: cleanedTransItem, programKey: this.program });
      }
    },
  },
};

</script>
