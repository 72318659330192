<template>
  <v-container>
    <PaidWarrantsProcessedDetails
      :program="program"
      v-if="profileType === 'importedPaidWarrants'"
    />
  </v-container>
</template>

<script>
import PaidWarrantsProcessedDetails from '@/components/bulk/PaidWarrantsProcessedDetails.vue';

export default {
  name: 'ViewFileProcessedDetails',
  created() {
    this.profileType = this.$route.params.type;
    this.fetchProgram();
  },
  data: () => ({
    profileType: '',
    program: '',
  }),
  components: {
    PaidWarrantsProcessedDetails,
  },
  methods: {
    fetchProgram() {
      this.program = ((this.$route || {}).query || {}).program;
    },
  },
};
</script>
